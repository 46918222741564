import React from "react"
import { useSelector } from "react-redux"

import Layout from "../components/layout"
import { HomePageQuery } from "../prismic/staticQueries"

const IndexPage = () => {
  const language = useSelector(state => state.language)
  const { show_price_feed } = HomePageQuery(language)
  const homePageData = HomePageQuery(language)
  return (
    <Layout head={false}>
      <>
      
      </>
    </Layout>
  )
}

export default IndexPage
